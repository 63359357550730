import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

declare const gwoCookiePopUpInit: Function;

@Injectable({
    providedIn: 'root'
})
export class CookiePopupService {
    readonly cookiePopupStyle: HTMLLinkElement = document.createElement('link');

    readonly cookiePopupScript: HTMLScriptElement = document.createElement('script');

    constructor() {
        this.cookiePopupStyle.rel = 'stylesheet';
        this.cookiePopupStyle.href = environment.cookies.styleUrl;

        this.cookiePopupScript.src = environment.cookies.scriptUrl;
        this.cookiePopupScript.type = 'text/javascript';
        this.cookiePopupScript.async = true;

        this.cookiePopupScript.onload = (): void => {
            gwoCookiePopUpInit();
        }
    }

    public initialize(): void {
        try {
            document.head.appendChild(this.cookiePopupStyle);
            document.head.appendChild(this.cookiePopupScript);
        } catch (e) {
            console.error('Error open cookie pop-up', e);
        }
    }
}
