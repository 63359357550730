import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from "../../../environments/environment";
import {CookieService} from "ngx-cookie-service";

declare var gtag: Function;

interface GwoCookeConsent {
    advertising: number,
    functional: number,
    performance: number,
}

const USER_NOT_CONSENTED = 0;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    constructor(
        private readonly cookieService: CookieService,
        private router: Router,
    ) {
    }

    public initialize(): void {
        /* @description
        * https://redmine.gwo.pl/issues/33356
        * */
        const gwoCookieString: GwoCookeConsent = JSON.parse(this.cookieService.get('gwo-cookie-consent'));
        if (gwoCookieString.performance === USER_NOT_CONSENTED) return;

        this.onRouteChange();

        try {
            const url = 'https://www.googletagmanager.com/gtag/js?id=';
            const gTagScript = document.createElement('script');
            gTagScript.async = true;
            gTagScript.src = `${url}${environment.googleAnalyticsId}`;
            document.head.appendChild(gTagScript);

            const dataLayerScript = document.createElement('script');
            dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticsId}');`;
            document.head.appendChild(dataLayerScript);
        } catch (e) {
            console.error('Error adding Google Analytics', e);
        }
    }

    private onRouteChange(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', environment.googleAnalyticsId, {
                    page_path: event.urlAfterRedirects,
                });
            }
        });
    }

    public event(action: string, eventCategory?: string, eventLabel?: string, value?: string): void {
        gtag('event', action, {
            ...(eventCategory && {event_category: eventCategory}),
            ...(eventLabel && {event_label: eventLabel}),
            ...(value && {value: value}),
        });
    }
}
