import {Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {GoogleAnalyticsService} from "./services/google-analytics/google-analytics.service";
import {AiChatService} from "./services/ai-chat/ai-chat.service";
import {CookiePopupService} from "./services/coookie-popup/cookie-popup.service";

@Component({
    selector: 'my-gwo-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    providers: [
        AiChatService,
    ]
})
export class AppComponent implements OnInit {
    title = 'Moje-GWO';

    constructor(
        private readonly googleAnalyticsService: GoogleAnalyticsService,
        private readonly cookiePopupService: CookiePopupService,
    ) {
    }

    ngOnInit(): void {
        if (environment.production) {
            this.googleAnalyticsService.initialize();
        }
        this.cookiePopupService.initialize();
    }
}
