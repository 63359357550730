import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthService} from "../../../services/auth/auth.service";
import {EMPTY, filter, first, of, switchMap, tap} from "rxjs";
import {UserActions} from "../actions";
import {catchError, map} from "rxjs/operators";
import {ErrorFacade} from "../facade/error.facade";
import {AuthRedirectService} from "../../../services/auth-redirect.service";
import {AnnouncementModel, NotificationModel} from "../../../models/interfaces/notification-model";
import {UserFacade} from "../facade/user.facade";
import {ALARM_MESSAGE_ID, EVENT_NOTIFY_ID} from "../../../models/const/variables";
import { NewsService } from "../../../services/news/news.service";
import { EventsService } from "../../../services/events/events.service";
import { CALENDAR_EVENTS_INITIAL_DATE_FROM, CALENDAR_EVENTS_INITIAL_DATE_TO } from "../../../../utils/consts/const-dates";

@Injectable({providedIn: 'root'})
export class UserEffects {
    readonly initUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initUser),
        tap(() => this.userFacade.setLoading(true)),
        switchMap(() => this.authService.getUserData().pipe(
            catchError((error) => {
                this.errorFacade.onShowError(error);
                this.userFacade.setLoading(false);
                throw error;
            }),
            map((user) => UserActions.initAuthUserData({user})),
            tap(() => this.userFacade.setLoading(false))
        )),
    ));

    readonly getDictionary$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initAuthUserData),
        first(),
        switchMap(() => this.authService.getDictionary().pipe(
            catchError((error) => {
                this.errorFacade.onShowError(error);
                throw error;
            }),
            map((dictionary) => UserActions.setDictionary({dictionary}))
        ))
    ));

    readonly getUserAnnouncement$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initUser),
        first(),
        switchMap(() => this.authService.getUserAnnouncement().pipe(
            filter((data) => !!data?.data?.length),
            map(({data}) => data),
            map((data) => {
                const alarmNotify: AnnouncementModel[] = data.filter((notify) => notify.type === ALARM_MESSAGE_ID);

                if (alarmNotify.length) {
                    const notification = new NotificationModel(alarmNotify, false, false, true, true);
                    this.userFacade.setNotification(notification);
                }

                return data.filter((notify) => notify.type !== ALARM_MESSAGE_ID) as AnnouncementModel[];
            }),
            map((data) => {
                const eventNotify: AnnouncementModel[] = data.filter((notify) => notify.type === EVENT_NOTIFY_ID);

                if (eventNotify.length) {
                    const notification = new NotificationModel(eventNotify, true, true, true, false, true);
                    this.userFacade.setNotification(notification);
                }

                return data.filter((notify) => notify.type !== EVENT_NOTIFY_ID) as AnnouncementModel[];
            }),
            filter((data) => !!data.length),
            map((data) => {
                const notification = new NotificationModel(data, false, false, true);

                return UserActions.setNotification({notification});
            })
        ))
    ));

    readonly initUserMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.initAuthUserData),
            map(() => UserActions.initNews())
        )
    );

    readonly initUserSimpleCalendarEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.initAuthUserData),
            map((action) => {
                if (action.user.isQuasiUser) {
                    // If user is a quasi-user, dispatch addCalendarEvent with null data
                    return UserActions.addSidebarCalendarEvent({eventsData: []});
                } else {
                    return UserActions.initSidebarCalendarEvents();
                }
            })
        )
    );

    readonly updateUser$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.refreshUser),
        switchMap(() => this.authService.getUserData().pipe(
            map((user) => UserActions.updateUser({user})),
        )),
    ));

    readonly removeAuthToken$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.setLoggedOut),
        tap(() => this.authRedirectService.redirectToLogOut()),
        first(),
    ), {dispatch: false});


    readonly getNewsData$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initNews),
        switchMap(() => this.newsService.getNews().pipe(
            map((newsApiResponse) => UserActions.setNews({ newsData: newsApiResponse.data })),
            catchError((error) => {
            this.errorFacade.onShowError(error);
            return of(UserActions.setNews({ newsData: [] }));
            })
        ))
    ));

    readonly loadSidebarCalendarEventsDataChunk$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.loadSidebarCalendarEventsDataChunk),
        switchMap((action) => {
            const { dateFrom, dateTo } = action;
            return this.eventsService.getCalendarEvents(dateFrom, dateTo).pipe(
                map(eventsData =>
                    UserActions.addSidebarCalendarEvent({ eventsData: eventsData.data })
                ),
                catchError(error => {
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    readonly initSidebarCalendarEvents$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initSidebarCalendarEvents),
        switchMap(() => {
            return this.eventsService.getCalendarEvents(CALENDAR_EVENTS_INITIAL_DATE_FROM, CALENDAR_EVENTS_INITIAL_DATE_TO).pipe(
                map(eventsData =>
                    UserActions.addSidebarCalendarEvent({ eventsData: eventsData.data }),
                ),
                catchError(error => {
                    this.errorFacade.onShowError(error);
                    return of(UserActions.addSidebarCalendarEvent({ eventsData: [] }));
                })
            );
        })
    ));


    constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService,
        private readonly authRedirectService: AuthRedirectService,
        private readonly eventsService: EventsService,
        private readonly errorFacade: ErrorFacade,
        private readonly userFacade: UserFacade,
        private readonly newsService: NewsService,
    ) {
    }
}
